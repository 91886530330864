<template>
  <div class="single-container pt-8">
    <!-- <div class="flex items-center cursor-pointer" @click="selectMode(repaymentModes.overdue)" v-if="getAccruedInterest">
      <div>
        <img
          src="@/assets/icons/selected.svg"
          alt=""
          class="place-self-center dot-active mr-4"
          v-if="activePaymentMethod === repaymentModes.overdue"
        />
        <div class="dot mr-4" v-else></div>
      </div>
      <p v-if="getAccruedInterest">Pay overdue payments of {{ getAccruedInterest | formatMoney }}</p>
    </div>
    <span class="line" v-if="getAccruedInterest"></span> -->
    <div class="flex items-center cursor-pointer" @click="selectMode(repaymentModes.purchase)" v-if="showFirstOption">
      <div>
        <img
          src="@/assets/icons/selected.svg"
          alt=""
          class="place-self-center dot-active mr-4"
          v-if="activePaymentMethod === repaymentModes.purchase"
        />
        <div class="dot mr-4" v-else></div>
      </div>
      <p v-if="singleScheduleData.recoverySchedules">Pay next instalment of {{ getNextRepaymentPrice.amountOwed | formatMoney }}</p>
    </div>
    <span class="line" v-if="showFirstOption"></span>
    <div class="flex items-center cursor-pointer" @click="selectMode(repaymentModes.instant)">
      <div>
        <img
          src="@/assets/icons/selected.svg"
          alt=""
          class="place-self-center dot-active mr-4"
          v-if="activePaymentMethod === repaymentModes.instant"
        />
        <div class="dot mr-4" v-else></div>
      </div>
      <p>Make full repayment of {{ singleScheduleData.amountOwed | formatMoney }}</p>
    </div>
    <span class="line"></span>
    <div class="flex items-center cursor-pointer pb-2" @click="selectMode(repaymentModes.partial)">
      <div>
        <img
          src="@/assets/icons/selected.svg"
          alt=""
          class="place-self-center dot-active mr-4"
          v-if="activePaymentMethod === repaymentModes.partial"
        />
        <div class="dot mr-4" v-else></div>
      </div>
      <p>Input specific amount to repay</p>
    </div>
    <LargeModal display size="sm-large" v-if="activePaymentMethod.length" @close="activePaymentMethod = ''" background="white">
      <component
        :is="activePaymentMethod"
        :singleScheduleData="singleScheduleData"
        :type="type"
        @completed="handleCompleted"
        @back="activePaymentMethod = ''"
      />
    </LargeModal>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Select } from "@/UI/Input";
  import { LargeModal } from "@/UI/Modals";

  export default {
    props: {
      singleScheduleData: {
        type: Object,
        default: () => {},
        required: true,
      },
      type: {
        type: String,
        default: "repayment",
        required: false,
      },
    },
    components: {
      Select,
      LargeModal,
      Instant: () => import("./Instant.vue"),
      Partial: () => import("./Partial"),
      PurchaseRepayment: () => import("./PurchaseRepayment"),
    },
    data: () => ({
      activePaymentMethod: "",
      repaymentModes: {
        instant: "Instant",
        partial: "Partial",
        purchase: "PurchaseRepayment",
        overdue:'overdue'
      },
    }),
    computed: {
      ...mapState({
        userCards: (state) => state.dashboard.userCards,
      }),
      showFirstOption() {
        return (
          this.type === "purchase" &&
          this.singleScheduleData.amountOwed !==
            this.singleScheduleData?.recoverySchedules[this.singleScheduleData?.recoverySchedules.length - 1]?.amountOwed
        );
      },
      getNextRepaymentPrice() {
        return this.singleScheduleData?.recoverySchedules.find((repayment) => repayment.amountOwed > 0);
      },
      getAccruedInterest() {
        if (this.singleScheduleData?.recoverySchedules.length) {
          let sumValue = 0;
          this.singleScheduleData?.recoverySchedules.forEach((item) => {
            // console.log(item, "item");
            sumValue += item.accruedOverdueInterest;
          });
          return sumValue;
        }
      },
    },
    methods: {
      ...mapActions("dashboard", ["getCards"]),
      ...mapActions("notification", ["showAlert"]),
      handleCompleted() {
        this.handleCompleted = "";
        this.$emit("completed");
      },
      selectMode(mode) {
        this.activePaymentMethod = mode;
      },
    },
  };
</script>
<style scoped>
  .single-container {
    min-height: 10rem;
  }
  .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #d5ccff;
  }
  .dot-active {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #d5ccff;
    padding: 2px;
  }

  .line {
    height: 1px;
    width: 100%;
    background: #e3e3e5;
    margin: 24px 0;
    display: inline-block;
  }
</style>
