var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-container pt-8" },
    [
      _vm.showFirstOption
        ? _c(
            "div",
            {
              staticClass: "flex items-center cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.selectMode(_vm.repaymentModes.purchase)
                },
              },
            },
            [
              _c("div", [
                _vm.activePaymentMethod === _vm.repaymentModes.purchase
                  ? _c("img", {
                      staticClass: "place-self-center dot-active mr-4",
                      attrs: {
                        src: require("@/assets/icons/selected.svg"),
                        alt: "",
                      },
                    })
                  : _c("div", { staticClass: "dot mr-4" }),
              ]),
              _vm.singleScheduleData.recoverySchedules
                ? _c("p", [
                    _vm._v(
                      "Pay next instalment of " +
                        _vm._s(
                          _vm._f("formatMoney")(
                            _vm.getNextRepaymentPrice.amountOwed
                          )
                        )
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.showFirstOption ? _c("span", { staticClass: "line" }) : _vm._e(),
      _c(
        "div",
        {
          staticClass: "flex items-center cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.selectMode(_vm.repaymentModes.instant)
            },
          },
        },
        [
          _c("div", [
            _vm.activePaymentMethod === _vm.repaymentModes.instant
              ? _c("img", {
                  staticClass: "place-self-center dot-active mr-4",
                  attrs: {
                    src: require("@/assets/icons/selected.svg"),
                    alt: "",
                  },
                })
              : _c("div", { staticClass: "dot mr-4" }),
          ]),
          _c("p", [
            _vm._v(
              "Make full repayment of " +
                _vm._s(_vm._f("formatMoney")(_vm.singleScheduleData.amountOwed))
            ),
          ]),
        ]
      ),
      _c("span", { staticClass: "line" }),
      _c(
        "div",
        {
          staticClass: "flex items-center cursor-pointer pb-2",
          on: {
            click: function ($event) {
              return _vm.selectMode(_vm.repaymentModes.partial)
            },
          },
        },
        [
          _c("div", [
            _vm.activePaymentMethod === _vm.repaymentModes.partial
              ? _c("img", {
                  staticClass: "place-self-center dot-active mr-4",
                  attrs: {
                    src: require("@/assets/icons/selected.svg"),
                    alt: "",
                  },
                })
              : _c("div", { staticClass: "dot mr-4" }),
          ]),
          _c("p", [_vm._v("Input specific amount to repay")]),
        ]
      ),
      _vm.activePaymentMethod.length
        ? _c(
            "LargeModal",
            {
              attrs: { display: "", size: "sm-large", background: "white" },
              on: {
                close: function ($event) {
                  _vm.activePaymentMethod = ""
                },
              },
            },
            [
              _c(_vm.activePaymentMethod, {
                tag: "component",
                attrs: {
                  singleScheduleData: _vm.singleScheduleData,
                  type: _vm.type,
                },
                on: {
                  completed: _vm.handleCompleted,
                  back: function ($event) {
                    _vm.activePaymentMethod = ""
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }