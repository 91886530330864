var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gap-4 min-h-full" },
    [
      _c("Tabs", {
        attrs: { options: _vm.tabOptions },
        model: {
          value: _vm.currentTab,
          callback: function ($$v) {
            _vm.currentTab = $$v
          },
          expression: "currentTab",
        },
      }),
      _vm.currentTab === "Pending Payment" ? _c("Pending") : _vm._e(),
      _vm.currentTab === "Purchase History"
        ? _c("Purchase", { on: { showPurchase: _vm.handleSinglePurchase } })
        : _vm._e(),
      _c(
        "LargeModal",
        {
          attrs: {
            background: "#EBEAEE",
            display: _vm.showPurchase,
            size: "sm-large",
          },
          on: { close: _vm.handleCloseSinglePurchase },
        },
        [
          _c("SinglePurchase", {
            attrs: {
              singlePayment: _vm.singlePayment,
              paymentDetails: _vm.paymentDetails,
            },
            on: { completed: _vm.handleCompletedRepayment },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }