<template>
  <!-- would be nice if you can separate them to components upcoming and overdue -->
  <div class="mt-8">
    <div class="text-center mx-auto" v-if="!upcomingPayments.length && !upcomingLoading">
      <img
        class="text-center mx-auto"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1676412034/customer/pending_payment_cx9m0e.svg"
        alt=""
      />
      <EmptyState description="You don’t have any upcoming payments yet" />
    </div>
    <Loader v-if="upcomingLoading" />
    <div v-else>
      <div v-if="overduePayments.length">
        <h3 class="md:text-lg text-grey text-sm font-semibold">Overdue</h3>
        <Loader v-if="upcomingLoading" />
        <div v-else>
          <EmptyState description="No overdue payments" v-if="overduePayments.length === 0" />
          <Card p="p-0" class="mt-4"  v-else>
            <div class="flex items-center purchase-item justify-between cursor-pointer p-4" @click="handleSinglePurchase(item)" v-for="item in overduePayments" :key="item.id">
              <div class="flex items-center">
                <div class="merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1">
                  <img
                    class="rounded-full max-h-10 max-w-full"
                    :src="getImage()"
                    alt="logo"
                  />
                </div>
                <div>
                  <p class="font-semibold">{{ item.orderTitle }}</p>
                  <p class="mt-2 text-xs text-brandRed">due {{ item.dueDate | timeBetween }}</p>
                </div>
              </div>
              <div>
                <p class="font-bold text-grey text-right">
                  {{ item.amountOwed | formatMoney }}
                </p>
                <p class="mt-2 text-xs text-right text-grey">of {{ item.amount | formatMoney }}</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div v-if="duePayments.length">
        <h3 class="md:text-lg text-grey text-sm font-semibold">Due</h3>
        <Loader v-if="upcomingLoading" />
        <div v-else>
          <EmptyState description="No due payments" v-if="duePayments.length === 0" />
          <Card p="p-0" class="mt-2"   v-else>
            <div class="flex  p-4 purchase-item items-center justify-between cursor-pointer" @click="handleSinglePurchase(item)" v-for="item in duePayments" :key="item.id">
              <div class="flex items-center">
                <div class="merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1">
                  <img
                    class="rounded-full max-h-10 max-w-full"
                    :src="getImage()"
                    alt="logo"
                  />
                </div>
                <div>
                  <p class="font-semibold">{{ item.orderTitle }}</p>
                  <p class="mt-2 text-xs text-brandRed">due {{ item.dueDate | timeBetween }}</p>
                </div>
              </div>
              <div>
                <p class="font-bold text-grey text-right">
                  {{ item.amountOwed | formatMoney }}
                </p>
                <p class="mt-2 text-xs text-right text-grey">of {{ item.amount | formatMoney }}</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div class="mt-8" v-if="upcomingPendingPayment.length">
        <h3 class="md:text-lg text-sm text-grey font-semibold">Upcoming</h3>
        <Loader v-if="upcomingLoading" />
        <div v-else>
          <EmptyState description="No upcoming payments" v-if="upcomingPendingPayment.length === 0" />
          <Card p="p-0" class="mt-2"   v-else>
            <div class="flex p-4 purchase-item items-center justify-between cursor-pointer" @click="handleSinglePurchase(item)" v-for="item in upcomingPendingPayment" :key="item.id">
              <div class="flex items-center">
                <div class="merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1">
                  <img
                    class="rounded-full max-h-10 max-w-full"
                    :src="getImage()"
                    alt="logo"
                  />
                </div>

                <div>
                  <p class="font-semibold">{{ item.orderTitle }}</p>
                  <p class="mt-2 text-xs text-lightGrey-2">due {{ item.dueDate | timeBetween }}</p>
                </div>
              </div>
              <div>
                <p class="font-bold text-grey text-right">
                  {{ item.amountOwed | formatMoney }}
                </p>
                <p class="mt-2 text-xs text-right text-grey">of {{ item.amount | formatMoney }}</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
    <LargeModal background="#EBEAEE" :display="showSinglePurchase" size="sm-large" @close="handleCloseSinglePurchase">
      <SinglePurchase :singlePayment="singlePayment" :paymentDetails="paymentDetails" @completed="handleCompletedRepayment" />
    </LargeModal>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  dayjs.extend(relativeTime);
  import Loader from "./Loader";
  import { Modal, LargeModal } from "@/UI/Modals";
  import getImage from "@/mixins/getImage";
  import SinglePurchase from "../SinglePurchase";
  export default {
    mixins: [getImage],
    components: {
      EmptyState: () => import("@/UI/EmptyState"),
      Loader,
      Modal,
      SinglePurchase,
      LargeModal,
    },
    data: () => ({
      overdueLoading: false,
      upcomingLoading: false,
      overduePayments: [],
      duePayments: [],
      upcomingPendingPayment: [],
      // upcomingPayments: [],
      paymentDetails: {},
      singlePayment: {},
      scheduleBreakdown: {},
      showPayment: false,
      showOverduePayment: false,
      pendingPayemnts: [],
      showSinglePurchase: false,
      defaultLogo: "https://res.cloudinary.com/zillaafrica/image/upload/v1631281213/customer/Group_12946_yr3hik.svg",
    }),
    computed: {
      ...mapState({
        upcomingPayments: (state) => state?.repayments?.upcomingPayments,
      }),
      dayjs() {
        return dayjs;
      },
    },
    mounted() {
      if (!this.upcomingPayments.length) {
        this.getPendingPayments();
      } else {
        this.handleRepaymentDataSorting();
      }
    },

    methods: {
      ...mapActions("repayments", ["getRecoverySchedules", "getSingleSchedule", "getUpcomingPayments"]),
      ...mapActions("purchases", ["getSinglePurchase"]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("dashboard", ["getUserWallet"]),
      getRecentDetails() {
        this.showPayment = false;
        this.showOverduePayment = false;
        this.getOverduePayments();
        this.getUpcomingPayments();
        this.$store.commit("purchases/RESET_PURCHASE_STATE");
        this.$store.commit("repayments/RESET_REPAYMENT_STATE");
      },
      handleSinglePurchase(item) {
        this.setLoading(true);
        if (!item.doNotRoute) {
          this.$router.replace({
            path: "/purchases",
            query: { id: item.purchaseId },
          });
        }
        this.getSinglePurchase(item.purchaseId)
          .then((res) => {
            this.paymentDetails = res.data;
            this.setLoading(false);
            this.showSinglePurchase = true;
          })
          .catch(() => {
            this.setLoading(false);
            this.showSinglePurchase = false;
          });
      },
      getPendingPayments() {
        this.upcomingLoading = true;
        this.getUpcomingPayments()
          .then((res) => {
            this.handleRepaymentDataSorting();
            this.upcomingLoading = false;
          })
          .catch(() => {
            this.upcomingLoading = false;
          });
      },
      handleCloseSinglePurchase() {
        this.showSinglePurchase = false;
        this.$router.replace({
          path: "purchases",
          query: {},
        });
      },
      handleCompletedRepayment() {
        this.showSinglePurchase = false;
        this.overduePayments = [];
        this.upcomingPendingPayment = [];
        this.duePayments = [];
        this.$store.commit("purchases/RESET_PURCHASE_STATE");
        this.$store.commit("repayments/RESET_REPAYMENT_STATE");
        this.getPendingPayments();
        this.getUserWallet();
      },

      handleRepaymentDataSorting() {
        let todaysDate = new Date();
        this.upcomingPayments.forEach((payment) => {
          if (dayjs(payment.dueDate).endOf("day").isAfter(todaysDate, "day")) {
            this.upcomingPendingPayment.push(payment);
          } else if (dayjs(payment.dueDate).endOf("day").isBefore(todaysDate, "day") && payment.status === "OVERDUE") {
            this.overduePayments.push(payment);
          } else {
            this.duePayments.push(payment);
          }
        });
      },
      // handlePaymentPlan(type) {
      //   let plan;
      //   switch (type) {
      //     case "INSTALLMENT":
      //       plan = "4";
      //       break;
      //     case "STRETCH":
      //       plan = "3";
      //       break;
      //     case "SPLIT":
      //       plan = "2 payments";
      //       break;
      //     case "INSTANT":
      //       plan = "paid in full";
      //   }
      //   return plan;
      // },
    },
  };
</script>
<style scoped>
 .purchase-item{
    border-bottom: 1px solid #EDEDF0
  }
</style>
