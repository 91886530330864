var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      !_vm.upcomingPayments.length && !_vm.upcomingLoading
        ? _c(
            "div",
            { staticClass: "text-center mx-auto" },
            [
              _c("img", {
                staticClass: "text-center mx-auto",
                attrs: {
                  src: "https://res.cloudinary.com/zillaafrica/image/upload/v1676412034/customer/pending_payment_cx9m0e.svg",
                  alt: "",
                },
              }),
              _c("EmptyState", {
                attrs: {
                  description: "You don’t have any upcoming payments yet",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.upcomingLoading
        ? _c("Loader")
        : _c("div", [
            _vm.overduePayments.length
              ? _c(
                  "div",
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "md:text-lg text-grey text-sm font-semibold",
                      },
                      [_vm._v("Overdue")]
                    ),
                    _vm.upcomingLoading
                      ? _c("Loader")
                      : _c(
                          "div",
                          [
                            _vm.overduePayments.length === 0
                              ? _c("EmptyState", {
                                  attrs: { description: "No overdue payments" },
                                })
                              : _c(
                                  "Card",
                                  { staticClass: "mt-4", attrs: { p: "p-0" } },
                                  _vm._l(_vm.overduePayments, function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass:
                                          "flex items-center purchase-item justify-between cursor-pointer p-4",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSinglePurchase(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex items-center" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "rounded-full max-h-10 max-w-full",
                                                  attrs: {
                                                    src: _vm.getImage(),
                                                    alt: "logo",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.orderTitle)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mt-2 text-xs text-brandRed",
                                                },
                                                [
                                                  _vm._v(
                                                    "due " +
                                                      _vm._s(
                                                        _vm._f("timeBetween")(
                                                          item.dueDate
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-bold text-grey text-right",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatMoney")(
                                                      item.amountOwed
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mt-2 text-xs text-right text-grey",
                                            },
                                            [
                                              _vm._v(
                                                "of " +
                                                  _vm._s(
                                                    _vm._f("formatMoney")(
                                                      item.amount
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.duePayments.length
              ? _c(
                  "div",
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "md:text-lg text-grey text-sm font-semibold",
                      },
                      [_vm._v("Due")]
                    ),
                    _vm.upcomingLoading
                      ? _c("Loader")
                      : _c(
                          "div",
                          [
                            _vm.duePayments.length === 0
                              ? _c("EmptyState", {
                                  attrs: { description: "No due payments" },
                                })
                              : _c(
                                  "Card",
                                  { staticClass: "mt-2", attrs: { p: "p-0" } },
                                  _vm._l(_vm.duePayments, function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass:
                                          "flex  p-4 purchase-item items-center justify-between cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSinglePurchase(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex items-center" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "rounded-full max-h-10 max-w-full",
                                                  attrs: {
                                                    src: _vm.getImage(),
                                                    alt: "logo",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.orderTitle)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mt-2 text-xs text-brandRed",
                                                },
                                                [
                                                  _vm._v(
                                                    "due " +
                                                      _vm._s(
                                                        _vm._f("timeBetween")(
                                                          item.dueDate
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-bold text-grey text-right",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatMoney")(
                                                      item.amountOwed
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mt-2 text-xs text-right text-grey",
                                            },
                                            [
                                              _vm._v(
                                                "of " +
                                                  _vm._s(
                                                    _vm._f("formatMoney")(
                                                      item.amount
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.upcomingPendingPayment.length
              ? _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "md:text-lg text-sm text-grey font-semibold",
                      },
                      [_vm._v("Upcoming")]
                    ),
                    _vm.upcomingLoading
                      ? _c("Loader")
                      : _c(
                          "div",
                          [
                            _vm.upcomingPendingPayment.length === 0
                              ? _c("EmptyState", {
                                  attrs: {
                                    description: "No upcoming payments",
                                  },
                                })
                              : _c(
                                  "Card",
                                  { staticClass: "mt-2", attrs: { p: "p-0" } },
                                  _vm._l(
                                    _vm.upcomingPendingPayment,
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass:
                                            "flex p-4 purchase-item items-center justify-between cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleSinglePurchase(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "merchant-logo h-12 w-12 mr-2 rounded-full grid place-items-center border border-lightGrey-1",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "rounded-full max-h-10 max-w-full",
                                                    attrs: {
                                                      src: _vm.getImage(),
                                                      alt: "logo",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c("div", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "font-semibold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.orderTitle)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mt-2 text-xs text-lightGrey-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "due " +
                                                        _vm._s(
                                                          _vm._f("timeBetween")(
                                                            item.dueDate
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-bold text-grey text-right",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("formatMoney")(
                                                        item.amountOwed
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "mt-2 text-xs text-right text-grey",
                                              },
                                              [
                                                _vm._v(
                                                  "of " +
                                                    _vm._s(
                                                      _vm._f("formatMoney")(
                                                        item.amount
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
      _c(
        "LargeModal",
        {
          attrs: {
            background: "#EBEAEE",
            display: _vm.showSinglePurchase,
            size: "sm-large",
          },
          on: { close: _vm.handleCloseSinglePurchase },
        },
        [
          _c("SinglePurchase", {
            attrs: {
              singlePayment: _vm.singlePayment,
              paymentDetails: _vm.paymentDetails,
            },
            on: { completed: _vm.handleCompletedRepayment },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }