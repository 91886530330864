var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "purchase-container" }, [
    _c(
      "div",
      { staticClass: "w-full" },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _vm.loading
              ? _c("Loader")
              : _c(
                  "div",
                  [
                    !_vm.getPurchases.length
                      ? _c(
                          "div",
                          [
                            _c("img", {
                              staticClass: "text-center mx-auto",
                              attrs: {
                                src: "https://res.cloudinary.com/zillaafrica/image/upload/v1676411732/customer/purchase_history_h3jok7.svg",
                                alt: "",
                              },
                            }),
                            _c("EmptyState", {
                              staticClass: "mt-16",
                              attrs: {
                                description: "You don't have any purchase yet",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "Card",
                      { staticClass: "mt-4", attrs: { p: "p-0" } },
                      _vm._l(_vm.getPurchases, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id + item.merchantLogo,
                            staticClass:
                              "flex items-center cursor-pointer purchase-item justify-between p-4",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("showPurchase", item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "flex items-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "logo-image h-12 w-12 mr-2 border border-lightGrey-1 rounded-full",
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "rounded-full max-h-10 max-w-full",
                                    attrs: {
                                      src: _vm.getImage(item.merchantLogo),
                                      alt: "logo",
                                    },
                                    on: { error: _vm.handleErrorImage },
                                  }),
                                ]
                              ),
                              _c("div", [
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v(" " + _vm._s(item.orderTitle) + " "),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "mt-2 text-xs text-grey" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .dayjs(item.createdAt)
                                            .format("MMM D YYYY | hh:mmA")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass: "font-bold text-grey text-right",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatMoney")(
                                          item.amountOfOrder
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mt-2 text-xs text-right text-blue",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.handlePaymentPlan(item.paymentPlan)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
        _c("Pagination", {
          attrs: { handleMore: _vm.handleMore, loading: _vm.paginateLoading },
          on: { paginate: _vm.handlePagination },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }