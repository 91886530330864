var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c(
        "Card",
        { staticClass: "mt-2", attrs: { p: "p-0" } },
        _vm._l(5, function (i) {
          return _c(
            "div",
            {
              key: i,
              staticClass:
                "flex purchase-item items-center p-4 justify-between",
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("Skeleton", {
                    staticClass: "mr-4 hidden md:block",
                    attrs: {
                      width: "2.5rem",
                      height: "2.5rem",
                      radius: "5px",
                      type: "circle",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c("Skeleton", {
                        attrs: { width: "7rem", height: "1rem" },
                      }),
                      _c("Skeleton", {
                        attrs: { width: "5rem", height: "1rem" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Skeleton", { attrs: { width: "3rem", height: "1rem" } }),
                  _c("Skeleton", { attrs: { width: "6rem", height: "1rem" } }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }