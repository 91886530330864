<template>
  <Purchases />
</template>
<script>
  import Purchases from "@/components/Purchases";
  export default {
    components: {
      Purchases,
    },
  };
</script>
