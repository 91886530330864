<template>
  <div class="purchase-container">
    <div class="w-full">
      <transition name="fade" mode="out-in">
        <Loader v-if="loading" />
        <div v-else>
          <div v-if="!getPurchases.length">
            <img class="text-center mx-auto" src="https://res.cloudinary.com/zillaafrica/image/upload/v1676411732/customer/purchase_history_h3jok7.svg" alt="" />
            <EmptyState class="mt-16" description="You don't have any purchase yet"  />
          </div>
          <Card p="p-0" class="mt-4">
            <div class="flex items-center cursor-pointer purchase-item justify-between p-4" @click="$emit('showPurchase', item)" v-for="item in getPurchases" :key="item.id + item.merchantLogo">
              <div class="flex items-center">
                <div class="logo-image h-12 w-12 mr-2 border border-lightGrey-1 rounded-full">
                  <img
                    class="rounded-full max-h-10 max-w-full"
                    :src="getImage(item.merchantLogo)"
                    alt="logo"
                    @error="handleErrorImage"
                  />
                </div>

                <div>
                  <p class="font-semibold">
                    {{ item.orderTitle }}
                  </p>
                  <p class="mt-2 text-xs text-grey">
                    {{ dayjs(item.createdAt).format("MMM D YYYY | hh:mmA") }}
                  </p>
                </div>
              </div>
              <div>
                <p class="font-bold text-grey text-right">
                  {{ item.amountOfOrder | formatMoney }}
                </p>
                <p class="mt-2 text-xs text-right text-blue">
                  {{ handlePaymentPlan(item.paymentPlan) }}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </transition>
      <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" />
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import dayjs from "dayjs";
  import Spinner from "@/UI/Spinner";
  import Pagination from "@/UI/Pagination";
  import getImage from "@/mixins/getImage";
import { LargeModal } from "@/UI/Modals";
  export default {
    mixins: [getImage],
    components: {
      EmptyState: () => import("@/UI/EmptyState"),
      Loader: () => import("./Loader"),
      Spinner,
      Pagination,
      LargeModal,
      SinglePurchase:() => import('../SinglePurchase')
    },
    props: {
      // loading: {
      //   type: Boolean,
      //   default: false,
      //   required: true,
      // },
      // getPurchases: {
      //   type: Array,
      //   default: () => [],
      //   required: true,
      // },
      // paginateLoading: {
      //   type: Boolean,
      //   default: false,
      //   required: false,
      // },
      // handleMore: {
      //   type: Boolean,
      //   default: false,
      //   required: false,
      // },
      // sort: {
      //   type: String,
      //   defaault: "",
      //   required: true,
      // },
    },
    data: () => ({
      paginateLoading: false,
      handleMore: false,
      loading: false,
      params: {
        orderId: "",
        page: 0,
        pageSize: 20,
        createdAtFrom: "",
        createdAtTo: "",
        paymentPlan: "",
        status: "SUCCESSFUL",
        sort: "DATE",
      },
    }),
    computed: {
      ...mapState({
        getPurchases: (state) => state.purchases.purchaseHistory,
      }),
      dayjs() {
        return dayjs;
      },
    },
    methods: {
      ...mapActions("purchases", ["getUserPurchases", "getPurchaseHistory", "getSinglePurchase"]),
      ...mapActions("loading", ["setLoading"]),
      // handleSinglePurchase(id) {
      //   this.setLoading(true);
      //   this.getSinglePurchase(id)
      //     .then((res) => {
      //       this.setLoading(false);
      //       this.$emit("showPurchase", res.data);
      //     })
      //     .catch(() => {
      //       this.setLoading(false);
      //     });
      // },
      handleErrorImage(e) {
        return (e.target.src = this.defaultImage);
      },
      handlePaymentPlan(type) {
        let plan;
        switch (type) {
          case "INSTALLMENT":
            plan = "4 payments";
            break;
          case "STRETCH":
            plan = "3 payments";
            break;
          case "SPLIT":
            plan = "2 payments";
            break;
          case "INSTANT":
            plan = "paid in full";
        }
        return plan;
      },
      callPurchases(type) {
        // if it is pagination type show loading for pagination else general loading
        type === "paginate" ? (this.paginateLoading = true) : (this.loading = true);
        this.getPurchaseHistory({
          params: this.params,
          type: type,
        })
          .then((res) => {
            if (res.data.length === this.params.pageSize) {
              this.handleMore = true;
            } else {
              this.handleMore = false;
            }
            type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
          })
          .catch(() => {
            type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
          });
      },
      handleLoading(type) {
        type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
      },
      handlePagination() {
        this.paginateLoading = true;
        this.params.page++;
        this.callPurchases("paginate");
      },
    },
    created() {
      if (this.getPurchases.length === 0) {
        this.callPurchases();
      }
    },
  };
</script>

<style scoped>
  @import "~@/assets/styles/table.css";
  @import "~@/assets/styles/animation.css";
  .logo-image {
    display: grid;
    place-items: center;
  }
  .purchase-item{
    border-bottom: 1px solid #EDEDF0
  }
  /* .purchase-container {
  min-height: calc(100vh - 15rem);
} */
</style>
