<template>
  <div>
    <div class="flex justify-between items-center">
      <h4 class="md:text-xl text-lg text-black font-bold w-3/5">
        {{ paymentDetails.orderTitle | truncate(200) }}
      </h4>
      <!-- <SecondaryButton v-if="paymentDetails.paymentStatus === 'SUCCESSFUL'" text="View Details" color="white" background="bg-brandPurple" @click="showReciept = true"/> -->
      <Options>
        <div class="option" @click.stop="showReciept = true">
          <div class="flex items-center">
            <svg-icon class="inline-block mr-1" color="#918E9C" size="16" type="mdi" :path="svgPath.file"></svg-icon>
            View details
          </div>
        </div>
        <div class="option" @click.stop="getRecords('reciept')">
          <div class="flex items-center">
            <svg-icon class="inline-block mr-1" color="#918E9C" size="16" type="mdi" :path="svgPath.reciept"></svg-icon>
            Download Reciept
          </div>
        </div>
        <div class="option" @click.stop="getRecords('share')" v-if="showShare">
          <div class="flex items-center">
            <svg-icon class="inline-block mr-1" color="#918E9C" size="16" type="mdi" :path="svgPath.share"></svg-icon>
            Share
          </div>
        </div>
      </Options>
    </div>

    <Card class="mt-4">
      <div class="flex items-center justify-between">
        <div>
          <!-- <div v-if="paymentDetails.paymentStatus === 'REFUNDED'" class="flex items-center justify-between">
            <div class="flex items-center justify-between">
              <p class="font-bold text-lg">
                {{ 0 | formatMoney }}
              </p>
              <p class="text-xs">
                Left to pay of
                <span class="font-bold text-sm">{{ paymentDetails.amountOfOrder | formatMoney }}</span>
                <span class="text-brandPurple font-semibold ml-5">{{ paymentDetails.paymentStatus | capitalize }}</span>
              </p>
            </div>
          </div> -->

          <div>
            <div class="flex items-center justify-between">
              <!-- <p v-if="paymentDetails.percentPaymentCompletion === 100">
                Paid in full
                <span class="text-blue ml-5">{{ paymentDetails.percentPaymentCompletion }}% completed</span>
              </p> -->
              <div>
                <p class="font-bold text-lg">
                  {{ paymentDetails.amountOwed | formatMoney }}
                </p>
                <p class="text-xs">
                  Left to pay of
                  <span class="font-bold text-sm">{{ paymentDetails.amountOfOrder | formatMoney }}</span>
                  <!-- <span class="text-blue ml-5">{{ paymentDetails.percentPaymentCompletion }}% paid</span> -->
                  <span
                    class="text-xs ml-2"
                    :class="[paymentDetails.recoverySchedules[paymentDetails.recoverySchedules.length - 1].status.toLowerCase()]"
                    >{{ paymentDetails.recoverySchedules[paymentDetails.recoverySchedules.length - 1].status | capitalize }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <SecondaryButton
          v-if="paymentDetails.amountOwed !== 0"
          p="px-4 py-2"
          :background="dateDueOrOverdue ? '#FC3C11' : 'bg-brandPurple'"
          color="text-white"
          text="Pay Up"
          @click="showPayNow = true"
        />
      </div>

      <HorizontalProgress :progress="paymentDetails.percentPaymentCompletion" :progressColor="dateDueOrOverdue ? '#FC3C11' : '#6956C8'" />

      <div class="mt-4">
        <p class="text-lightGrey-2 text-xs" v-if="paymentDetails.paymentStatus === 'REFUNDED'">
          Next payment:
          <span class="text-black"> Nil</span>
        </p>
        <p class="text-lightGrey-2 text-xs" v-if="paymentDetails.nextPaymentDueDate">
          Next payment:
          <span class="text-black"> {{ dayjs(paymentDetails.nextPaymentDueDate).format("MMMM DD YYYY") }}</span>
        </p>
        <p class="text-lightGrey-2 text-xs">
          Payment Plan:
          <span v-if="paymentDetails.paymentPlan" class="text-black">{{ paymentDetails.paymentPlan }}</span>
        </p>
        <p class="text-lightGrey-2 text-xs flex items-center" v-if="paymentDetails.loanProvider">
          Credit financed by:
          <span class="text-black flex items-center"
            ><img
              class="h-4 w-4 ml-2 mr-1"
              :src="getImage(paymentDetails.loanProvider.logoId)"
              alt="paymentDetails.loanProvider.providerName"
              v-if="paymentDetails.loanProvider.logoId"
            />
            {{ paymentDetails.loanProvider.providerName }}</span
          >
        </p>
      </div>
      <Hr class="my-4" color="#EDEDF0" />
      <div v-if="paymentDetails.issuedCardDetails" class="notice">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M7.25 4.66602H8.75V6.16602H7.25V4.66602ZM7.25 7.66602H8.75V12.166H7.25V7.66602ZM8 0.916016C3.86 0.916016 0.5 4.27602 0.5 8.41602C0.5 12.556 3.86 15.916 8 15.916C12.14 15.916 15.5 12.556 15.5 8.41602C15.5 4.27602 12.14 0.916016 8 0.916016ZM8 14.416C4.6925 14.416 2 11.7235 2 8.41602C2 5.10852 4.6925 2.41602 8 2.41602C11.3075 2.41602 14 5.10852 14 8.41602C14 11.7235 11.3075 14.416 8 14.416Z"
            fill="#2490BE"
          />
        </svg>
        <p class="text-grey-2 text-left">Your payment includes 5% processing fee for credit card payments.</p>
      </div>
      <div v-else class="flex items-center">
        <img class="mr-4 h-10 w-10 rounded-full" :src="merchantData.logo" alt="logo" />
        <div class="mr-4">
          <p class="text-sm">{{ paymentDetails.merchant.name }}</p>
          <p class="text-xs text-lightGrey-2">Merchant Name</p>
        </div>
        <div>
          <p class="text-sm">{{ paymentDetails.orderCode }}</p>
          <p class="text-xs text-lightGrey-2">Order ID</p>
        </div>
      </div>
    </Card>
    <!-- payment schedule -->
    <div v-if="paymentDetails.paymentStatus !== 'REFUNDED'">
      <p class="mt-8 text-grey text-sm">PAYMENT SCHEDULE</p>
      <Card class="mt-4">
        <div
          class="flex justify-between pl-8 relative"
          :class="{ 'split-container': index !== paymentDetails.recoverySchedules.length - 1 }"
          v-for="(item, index) in paymentDetails.recoverySchedules"
          :key="index"
        >
          <div class="dot2 absolute"></div>
          <div v-if="item.amountOwed === 0" class="dot absolute dot-shadow"></div>
          <div
            class="line absolute"
            v-if="index !== paymentDetails.recoverySchedules.length - 1"
            :class="{ 'active-line': item.amountOwed === 0 }"
          ></div>
          <div>
            <p class="text-sm schedule">
              {{ getTimelineText(index, paymentDetails.recoverySchedules.length - 1) }}
              <span
                class="cursor-pointer"
                v-if="item.status === 'OVERDUE'"
                v-tooltip="{
                  content: 'Click on this icon to see your interest breakdown',
                  placement: 'bottom',
                  trigger: 'top',
                }"
              >
                <svg-icon
                  class="inline-block mb-1"
                  color="#918E9C"
                  size="15"
                  type="mdi"
                  :path="svgPath.info"
                  @click.native="setOverduePaymentDetails(item, index)"
                ></svg-icon>
              </span>
            </p>

            <p class="text-xs text-lightGrey-2" :class="[item.status === 'OVERDUE' ? 'text-brandRed' : 'text-lightGrey-2']">
              <!-- {{ item.status === "SUCCESS" ? "Paid" : "Due" }} on -->
              <span v-if="item.completedAt && item.status === 'SUCCESS'"> Paid on {{ dayjs(item.completedAt).format("MMMM DD YYYY") }} </span>
              <span v-else-if="item.becameOverdueAt && item.status === 'OVERDUE'"> Overdue by {{ dayjs(item.becameOverdueAt).fromNow(true) }} </span>
              <span v-else> Due on {{ dayjs(item.dueDate).format("MMMM DD YYYY") }} </span>
            </p>
          </div>
          <h4 class="font-bold text-sm" v-if="item.amountOwed === 0">
            <s>{{ item.amount | formatMoney }}</s>
          </h4>
          <h4 v-else class="font-bold text-sm">
            {{ item.amountOwed | formatMoney }}
          </h4>
        </div>
      </Card>
    </div>
    <!-- payment records -->
    <p class="mt-8 text-grey text-sm">PAYMENT RECORDS</p>
    <Card class="mt-4">
      <div v-if="paymentDetails.paymentStatus === 'REFUNDED'">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="21"
              viewBox="0 0 17 21"
              fill="none"
              class="rounded-full mr-4 p-3 h-10 w-10 bg-lightPurple"
            >
              <path
                d="M8.5 2.41556V0.625558C8.5 0.175558 7.96 -0.0444421 7.65 0.275558L4.85 3.06556C4.65 3.26556 4.65 3.57556 4.85 3.77556L7.64 6.56556C7.96 6.87556 8.5 6.65556 8.5 6.20556V4.41556C11.81 4.41556 14.5 7.10556 14.5 10.4156C14.5 11.2056 14.35 11.9756 14.06 12.6656C13.91 13.0256 14.02 13.4356 14.29 13.7056C14.8 14.2156 15.66 14.0356 15.93 13.3656C16.3 12.4556 16.5 11.4556 16.5 10.4156C16.5 5.99556 12.92 2.41556 8.5 2.41556ZM8.5 16.4156C5.19 16.4156 2.5 13.7256 2.5 10.4156C2.5 9.62556 2.65 8.85556 2.94 8.16556C3.09 7.80556 2.98 7.39556 2.71 7.12556C2.2 6.61556 1.34 6.79556 1.07 7.46556C0.7 8.37556 0.5 9.37556 0.5 10.4156C0.5 14.8356 4.08 18.4156 8.5 18.4156V20.2056C8.5 20.6556 9.04 20.8756 9.35 20.5556L12.14 17.7656C12.34 17.5656 12.34 17.2556 12.14 17.0556L9.35 14.2656C9.04 13.9556 8.5 14.1756 8.5 14.6256V16.4156Z"
                fill="#6956C8"
              />
            </svg>
            <div>
              <p class="text-darkGrey font-semibold">Refund</p>
              <p v-if="paymentDetails.refundDate" class="text-lightGrey-2 text-xs">{{ paymentDetails.refundDate }}</p>
              <p v-else class="text-lightGrey-2 text-xs">Not available</p>
            </div>
          </div>
          <div class="font-semibold">{{ paymentDetails.refundedAmount | formatMoney }}</div>
        </div>
        <Hr class="mb-5" />
      </div>

      <div
        class="flex justify-between items-center"
        :class="{ 'mb-4': index !== paymentRecords.length - 1 }"
        v-for="(item, index) in paymentRecords"
        :key="index"
      >
        <div class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            class="rounded-full mr-4 p-3 h-10 w-10 first-record"
          >
            <path
              d="M5.50012 10.6163L2.00012 7.11633C1.61012 6.72633 0.990117 6.72633 0.600117 7.11633C0.210117 7.50633 0.210117 8.12633 0.600117 8.51633L4.79012 12.7063C5.18012 13.0963 5.81012 13.0963 6.20012 12.7063L16.8001 2.11633C17.1901 1.72633 17.1901 1.10633 16.8001 0.716328C16.4101 0.326328 15.7901 0.326328 15.4001 0.716328L5.50012 10.6163Z"
              fill="#10DF88"
            />
          </svg>
          <div>
            <p class="text-darkGrey font-semibold">Paid</p>
            <p class="text-lightGrey-2 text-xs" v-if="item.collectedAt">
              {{ dayjs(item.collectedAt).format("MMMM DD YYYY") }}
            </p>
          </div>
        </div>
        <div>
          {{ item.amountCollected | formatMoney }}
        </div>
      </div>
    </Card>
    <Reciept v-if="showReciept" :paymentDetails="paymentDetails" :display="showReciept" @close="showReciept = false" :recieptProp="recieptBlob" />
    <!-- modalfor paymebt -->
    <Modal :display="showPayNow" @close="showPayNow = false" title="How much do you want to pay">
      <SinglePayment type="purchase" :singleScheduleData="paymentDetails" @completed="handleCompleted" />
    </Modal>
    <!-- interest explainer -->
    <Modal :display="showOverdueModal" title="Interest breakdown" @close="showOverdueModal = false">
      <p class="text-sm text-lightGrey-2">A 2% interest accumulates daily when you miss a payment.</p>
      <div class="grid grid-cols-2 mt-3">
        <p class="text-sm text-lightGrey-2">Schedule:</p>
        <p class="text-dark">{{ singleOverduePayment.position }}</p>
      </div>
      <div class="grid grid-cols-2 mt-3">
        <p class="text-sm text-lightGrey-2">Amount paid:</p>
        <p class="text-dark">{{ singleOverduePayment.details.amountRecovered | formatMoney }}</p>
      </div>
      <div class="grid grid-cols-2 mt-3">
        <p class="text-sm text-lightGrey-2">Left to pay:</p>
        <p class="text-dark">{{ singleOverduePayment.details.amountOwed | formatMoney }}</p>
      </div>
      <div class="grid grid-cols-2 mt-3">
        <p class="text-sm text-lightGrey-2">Late payment interest:</p>
        <p class="text-dark">{{ singleOverduePayment.details.accruedOverdueInterest | formatMoney }}</p>
      </div>
      <Hr class="my-4" />
      <div class="flex ites-center justify-between flex-wrap">
        <p class="text-sm font-semibold text-lightGrey-2">Total amount owed</p>
        <p class="text-sm font-semibold text-dark">{{ 3000 | formatMoney }}</p>
        <p class="text-sm font-semibold text-brandPurple cursor-pointer" @click="showBreakdown = true">View details</p>
      </div>
    </Modal>
    <Modal :display="showBreakdown" @close="showBreakdown = false">
      <OverduePaymentBreakdown :paymentBreakdown="singleOverduePayment.details.additionalInterests" />
    </Modal>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  // import { MERCHANT_BRIEF } from "@/utils/api/merchant";
  import { SecondaryButton } from "@/UI/Button";
  import { HorizontalProgress } from "@/UI/Progress";
  import dayjs from "dayjs";
  import Hr from "@/UI/Hr";
  import { Modal } from "@/UI/Modals";
  import SinglePayment from "./PayNow";
  import getImage from "@/mixins/getImage";
  import { Options } from "@/UI/Navigation";
  import { mdiFileDocumentOutline, mdiShareVariant, mdiReceiptTextOutline, mdiInformationOutline } from "@mdi/js";
  import { GET_PURCHASE_RECIEPT } from "@/utils/api/purchase";
  import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
  dayjs.extend(relativeTime);
  dayjs.extend(isSameOrAfter);

  export default {
    mixins: [getImage],
    components: {
      Hr,
      SecondaryButton,
      HorizontalProgress,
      Modal,
      SinglePayment,
      Options,
      Reciept: () => import("../Reciept.vue"),
      Paynow: () => import("./PayNow"),
      OverduePaymentBreakdown: () => import("./PaymentBreakdown.vue"),
    },
    props: {
      paymentDetails: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data: () => ({
      loading: false,
      merchantData: {
        name: "",
        logo: null,
      },
      showPayNow: false,
      paymentRecords: [],
      refundedSum: 0,
      defaultLogo: "https://res.cloudinary.com/zillaafrica/image/upload/v1631281213/customer/Group_12946_yr3hik.svg",
      showReciept: false,
      showPayNow: false,
      svgPath: {
        file: mdiFileDocumentOutline,
        share: mdiShareVariant,
        reciept: mdiReceiptTextOutline,
        info: mdiInformationOutline,
      },
      recieptBlob: null,
      showOverdueModal: false,
      singleOverduePayment: {
        position: "",
        details: {},
      },
      showBreakdown: false,
    }),
    computed: {
      dayjs() {
        return dayjs;
      },
      showShare() {
        return !!navigator.share;
      },
      dateDueOrOverdue() {
        const dueExists = this.paymentDetails?.recoverySchedules.some((payment) => {
          return payment.status === "OVERDUE";
        });
        return dueExists;
      },
    },

    mounted() {
      this.getMerchantBrief();
      this.getRepaymentRecords();
      this.getAccruedInterest();
      // this.dateDueOrOverdue()
    },
    methods: {
      ...mapActions("loading", ["setLoading"]),
      getReciept(type) {
        this.setLoading({
          display: true,
          description: "Generating your reciept",
        });
        GET_PURCHASE_RECIEPT(this.paymentDetails.id)
          .then(({ data }) => {
            this.recieptBlob = data;
            this.setLoading(false);
            this.handleGetRecordAction(type);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      handleDownloadReciept() {
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(this.recieptBlob);
        anchor.setAttribute("download", "Zilla purchase Reciept");
        document.body.appendChild(anchor);
        anchor.click();
        // URL.revokeObjectURL(this.recieptBlob)
      },
      handleShareReciept() {
        const file = new File([this.recieptBlob], "reciept.png", { type: this.recieptBlob.type });
        const shareData = {
          title: "Zilla purchase reciept",
          files: [file],
        };
        if (navigator.share) {
          navigator
            .share(shareData)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }
      },
      getRecords(type) {
        if (this.recieptBlob) {
          this.handleGetRecordAction(type);
        } else {
          this.getReciept(type);
        }
      },
      handleGetRecordAction(type) {
        switch (type) {
          case "reciept":
            this.handleDownloadReciept(this.recieptBlob);
            break;
          case "share":
            this.handleShareReciept(this.recieptBlob);
            break;
        }
      },
      getMerchantBrief() {
        this.merchantData.logo = this.getImage(this.paymentDetails?.merchant?.logoId, this.defaultLogo);
      },
      handleCompleted() {
        this.showPayNow = false;
        this.$emit("completed");
      },
      getRepaymentRecords() {
        const records = [];
        for (let i = 0; i < this.paymentDetails?.recoverySchedules.length; i++) {
          if (this.paymentDetails?.recoverySchedules[i].repayments.length) {
            for (let j = 0; j < this.paymentDetails?.recoverySchedules[i].repayments.length; j++) {
              records.push(this.paymentDetails.recoverySchedules[i].repayments[j]);
            }
          }
        }
        this.paymentRecords = records;
        this.paymentRecords.forEach((element) => {
          this.refundedSum += element.amountCollected;
        });
      },
      getTimelineText(index, NoOfPayments) {
        let text;
        if (index === NoOfPayments) {
          text = "Last payment";
        } else if (index === 0) {
          text = "First payment";
        } else if (index === 1) {
          text = "Second payment";
        } else if (index === 2) {
          text = "Third payment";
        } else if (index === 3) {
          text = "Last payment";
        }
        return text;
      },
      // getAccruedInterest() {
      //   if (this.paymentDetails?.recoverySchedules.length) {
      //     let sumValue = 0;
      //     this.paymentDetails?.recoverySchedules.forEach((item) => {
      //       console.log(item, "item");
      //       sumValue += item.accruedOverdueInterest;
      //     });
      //     console.log(initialValue, "wjenwje");
      //     return sumValue;
      //   }
      // },
      // dateDueOrOverdue(){
      //   // let todaysDate = new Date();
      //   // if(dayjs(date).endOf('day').isSameOrAfter(todaysDate, 'day')){
      //   //   return true
      //   // }else{
      //   //   return false
      //   // }

      //  const dueExists =  this.paymentDetails?.recoverySchedules.some((payment) => {
      //     return payment.becameOverdueAt
      //  })
      //  console.log(dueExists)

      //   return  dueExists
      // },
      handleCompleted() {
        this.showPayNow = false;
        this.$emit("completed");
      },
      setOverduePaymentDetails(payment, position) {
        this.singleOverduePayment.details = payment;
        this.singleOverduePayment.position = this.getTimelineText(position, this.paymentDetails.recoverySchedules.length - 1);
        this.showOverdueModal = true;
      },
    },
  };
</script>
<style scoped>
  .section {
    margin-top: 1rem;
  }

  .option {
    padding: 18px 15px;
    font-size: 13px;
    color: #1e1d20;
    border-bottom: 1px solid #ebeaee;
    cursor: pointer;
  }
  .option .line {
    height: 1px;
    background: #ebeaee;
    width: 100%;
  }
  .dot {
    left: 0.3rem;
    top: 0;
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    background-color: #63dfdf;
    z-index: 10;
  }
  .dot2 {
    left: 0.3rem;
    top: 0;
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    background-color: #c8c8c8;
    z-index: 10;
  }
  .dot-shadow {
    box-shadow: 0px 0px 0px 5px rgba(99, 223, 223, 0.3);
  }
  .split-container {
    height: 3.5rem;
  }
  .schedule {
    margin-top: -0.5rem;
  }
  .line {
    height: 4rem;
    width: 1px;
    background-color: #c8c8c8;
    left: 0.5rem;
    top: 0;
  }
  .line:last-child {
    height: 0px !important;
    width: 0px !important;
  }
  .active-line {
    background-color: #63dfdf;
  }
</style>
