var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex justify-between items-center" },
        [
          _c(
            "h4",
            { staticClass: "md:text-xl text-lg text-black font-bold w-3/5" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("truncate")(_vm.paymentDetails.orderTitle, 200)
                  ) +
                  " "
              ),
            ]
          ),
          _c("Options", [
            _c(
              "div",
              {
                staticClass: "option",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.showReciept = true
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("svg-icon", {
                      staticClass: "inline-block mr-1",
                      attrs: {
                        color: "#918E9C",
                        size: "16",
                        type: "mdi",
                        path: _vm.svgPath.file,
                      },
                    }),
                    _vm._v(" View details "),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "option",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.getRecords("reciept")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("svg-icon", {
                      staticClass: "inline-block mr-1",
                      attrs: {
                        color: "#918E9C",
                        size: "16",
                        type: "mdi",
                        path: _vm.svgPath.reciept,
                      },
                    }),
                    _vm._v(" Download Reciept "),
                  ],
                  1
                ),
              ]
            ),
            _vm.showShare
              ? _c(
                  "div",
                  {
                    staticClass: "option",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getRecords("share")
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("svg-icon", {
                          staticClass: "inline-block mr-1",
                          attrs: {
                            color: "#918E9C",
                            size: "16",
                            type: "mdi",
                            path: _vm.svgPath.share,
                          },
                        }),
                        _vm._v(" Share "),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "Card",
        { staticClass: "mt-4" },
        [
          _c(
            "div",
            { staticClass: "flex items-center justify-between" },
            [
              _c("div", [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between" },
                    [
                      _c("div", [
                        _c("p", { staticClass: "font-bold text-lg" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.paymentDetails.amountOwed
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticClass: "text-xs" }, [
                          _vm._v(" Left to pay of "),
                          _c("span", { staticClass: "font-bold text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.paymentDetails.amountOfOrder
                                )
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "text-xs ml-2",
                              class: [
                                _vm.paymentDetails.recoverySchedules[
                                  _vm.paymentDetails.recoverySchedules.length -
                                    1
                                ].status.toLowerCase(),
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(
                                    _vm.paymentDetails.recoverySchedules[
                                      _vm.paymentDetails.recoverySchedules
                                        .length - 1
                                    ].status
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm.paymentDetails.amountOwed !== 0
                ? _c("SecondaryButton", {
                    attrs: {
                      p: "px-4 py-2",
                      background: _vm.dateDueOrOverdue
                        ? "#FC3C11"
                        : "bg-brandPurple",
                      color: "text-white",
                      text: "Pay Up",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showPayNow = true
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("HorizontalProgress", {
            attrs: {
              progress: _vm.paymentDetails.percentPaymentCompletion,
              progressColor: _vm.dateDueOrOverdue ? "#FC3C11" : "#6956C8",
            },
          }),
          _c("div", { staticClass: "mt-4" }, [
            _vm.paymentDetails.paymentStatus === "REFUNDED"
              ? _c("p", { staticClass: "text-lightGrey-2 text-xs" }, [
                  _vm._v(" Next payment: "),
                  _c("span", { staticClass: "text-black" }, [_vm._v(" Nil")]),
                ])
              : _vm._e(),
            _vm.paymentDetails.nextPaymentDueDate
              ? _c("p", { staticClass: "text-lightGrey-2 text-xs" }, [
                  _vm._v(" Next payment: "),
                  _c("span", { staticClass: "text-black" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .dayjs(_vm.paymentDetails.nextPaymentDueDate)
                            .format("MMMM DD YYYY")
                        )
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("p", { staticClass: "text-lightGrey-2 text-xs" }, [
              _vm._v(" Payment Plan: "),
              _vm.paymentDetails.paymentPlan
                ? _c("span", { staticClass: "text-black" }, [
                    _vm._v(_vm._s(_vm.paymentDetails.paymentPlan)),
                  ])
                : _vm._e(),
            ]),
            _vm.paymentDetails.loanProvider
              ? _c(
                  "p",
                  { staticClass: "text-lightGrey-2 text-xs flex items-center" },
                  [
                    _vm._v(" Credit financed by: "),
                    _c(
                      "span",
                      { staticClass: "text-black flex items-center" },
                      [
                        _vm.paymentDetails.loanProvider.logoId
                          ? _c("img", {
                              staticClass: "h-4 w-4 ml-2 mr-1",
                              attrs: {
                                src: _vm.getImage(
                                  _vm.paymentDetails.loanProvider.logoId
                                ),
                                alt: "paymentDetails.loanProvider.providerName",
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(_vm.paymentDetails.loanProvider.providerName)
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("Hr", { staticClass: "my-4", attrs: { color: "#EDEDF0" } }),
          _vm.paymentDetails.issuedCardDetails
            ? _c("div", { staticClass: "notice" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "16",
                      height: "16",
                      viewBox: "0 0 16 16",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M7.25 4.66602H8.75V6.16602H7.25V4.66602ZM7.25 7.66602H8.75V12.166H7.25V7.66602ZM8 0.916016C3.86 0.916016 0.5 4.27602 0.5 8.41602C0.5 12.556 3.86 15.916 8 15.916C12.14 15.916 15.5 12.556 15.5 8.41602C15.5 4.27602 12.14 0.916016 8 0.916016ZM8 14.416C4.6925 14.416 2 11.7235 2 8.41602C2 5.10852 4.6925 2.41602 8 2.41602C11.3075 2.41602 14 5.10852 14 8.41602C14 11.7235 11.3075 14.416 8 14.416Z",
                        fill: "#2490BE",
                      },
                    }),
                  ]
                ),
                _c("p", { staticClass: "text-grey-2 text-left" }, [
                  _vm._v(
                    "Your payment includes 5% processing fee for credit card payments."
                  ),
                ]),
              ])
            : _c("div", { staticClass: "flex items-center" }, [
                _c("img", {
                  staticClass: "mr-4 h-10 w-10 rounded-full",
                  attrs: { src: _vm.merchantData.logo, alt: "logo" },
                }),
                _c("div", { staticClass: "mr-4" }, [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(_vm._s(_vm.paymentDetails.merchant.name)),
                  ]),
                  _c("p", { staticClass: "text-xs text-lightGrey-2" }, [
                    _vm._v("Merchant Name"),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(_vm._s(_vm.paymentDetails.orderCode)),
                  ]),
                  _c("p", { staticClass: "text-xs text-lightGrey-2" }, [
                    _vm._v("Order ID"),
                  ]),
                ]),
              ]),
        ],
        1
      ),
      _vm.paymentDetails.paymentStatus !== "REFUNDED"
        ? _c(
            "div",
            [
              _c("p", { staticClass: "mt-8 text-grey text-sm" }, [
                _vm._v("PAYMENT SCHEDULE"),
              ]),
              _c(
                "Card",
                { staticClass: "mt-4" },
                _vm._l(
                  _vm.paymentDetails.recoverySchedules,
                  function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "flex justify-between pl-8 relative",
                        class: {
                          "split-container":
                            index !==
                            _vm.paymentDetails.recoverySchedules.length - 1,
                        },
                      },
                      [
                        _c("div", { staticClass: "dot2 absolute" }),
                        item.amountOwed === 0
                          ? _c("div", {
                              staticClass: "dot absolute dot-shadow",
                            })
                          : _vm._e(),
                        index !==
                        _vm.paymentDetails.recoverySchedules.length - 1
                          ? _c("div", {
                              staticClass: "line absolute",
                              class: { "active-line": item.amountOwed === 0 },
                            })
                          : _vm._e(),
                        _c("div", [
                          _c("p", { staticClass: "text-sm schedule" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getTimelineText(
                                    index,
                                    _vm.paymentDetails.recoverySchedules
                                      .length - 1
                                  )
                                ) +
                                " "
                            ),
                            item.status === "OVERDUE"
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content:
                                            "Click on this icon to see your interest breakdown",
                                          placement: "bottom",
                                          trigger: "top",
                                        },
                                        expression:
                                          "{\n                content: 'Click on this icon to see your interest breakdown',\n                placement: 'bottom',\n                trigger: 'top',\n              }",
                                      },
                                    ],
                                    staticClass: "cursor-pointer",
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "inline-block mb-1",
                                      attrs: {
                                        color: "#918E9C",
                                        size: "15",
                                        type: "mdi",
                                        path: _vm.svgPath.info,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.setOverduePaymentDetails(
                                            item,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "text-xs text-lightGrey-2",
                              class: [
                                item.status === "OVERDUE"
                                  ? "text-brandRed"
                                  : "text-lightGrey-2",
                              ],
                            },
                            [
                              item.completedAt && item.status === "SUCCESS"
                                ? _c("span", [
                                    _vm._v(
                                      " Paid on " +
                                        _vm._s(
                                          _vm
                                            .dayjs(item.completedAt)
                                            .format("MMMM DD YYYY")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : item.becameOverdueAt &&
                                  item.status === "OVERDUE"
                                ? _c("span", [
                                    _vm._v(
                                      " Overdue by " +
                                        _vm._s(
                                          _vm
                                            .dayjs(item.becameOverdueAt)
                                            .fromNow(true)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " Due on " +
                                        _vm._s(
                                          _vm
                                            .dayjs(item.dueDate)
                                            .format("MMMM DD YYYY")
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                        item.amountOwed === 0
                          ? _c("h4", { staticClass: "font-bold text-sm" }, [
                              _c("s", [
                                _vm._v(
                                  _vm._s(_vm._f("formatMoney")(item.amount))
                                ),
                              ]),
                            ])
                          : _c("h4", { staticClass: "font-bold text-sm" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatMoney")(item.amountOwed)
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    )
                  }
                ),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c("p", { staticClass: "mt-8 text-grey text-sm" }, [
        _vm._v("PAYMENT RECORDS"),
      ]),
      _c(
        "Card",
        { staticClass: "mt-4" },
        [
          _vm.paymentDetails.paymentStatus === "REFUNDED"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center mb-4" },
                    [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c(
                          "svg",
                          {
                            staticClass:
                              "rounded-full mr-4 p-3 h-10 w-10 bg-lightPurple",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "17",
                              height: "21",
                              viewBox: "0 0 17 21",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M8.5 2.41556V0.625558C8.5 0.175558 7.96 -0.0444421 7.65 0.275558L4.85 3.06556C4.65 3.26556 4.65 3.57556 4.85 3.77556L7.64 6.56556C7.96 6.87556 8.5 6.65556 8.5 6.20556V4.41556C11.81 4.41556 14.5 7.10556 14.5 10.4156C14.5 11.2056 14.35 11.9756 14.06 12.6656C13.91 13.0256 14.02 13.4356 14.29 13.7056C14.8 14.2156 15.66 14.0356 15.93 13.3656C16.3 12.4556 16.5 11.4556 16.5 10.4156C16.5 5.99556 12.92 2.41556 8.5 2.41556ZM8.5 16.4156C5.19 16.4156 2.5 13.7256 2.5 10.4156C2.5 9.62556 2.65 8.85556 2.94 8.16556C3.09 7.80556 2.98 7.39556 2.71 7.12556C2.2 6.61556 1.34 6.79556 1.07 7.46556C0.7 8.37556 0.5 9.37556 0.5 10.4156C0.5 14.8356 4.08 18.4156 8.5 18.4156V20.2056C8.5 20.6556 9.04 20.8756 9.35 20.5556L12.14 17.7656C12.34 17.5656 12.34 17.2556 12.14 17.0556L9.35 14.2656C9.04 13.9556 8.5 14.1756 8.5 14.6256V16.4156Z",
                                fill: "#6956C8",
                              },
                            }),
                          ]
                        ),
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "text-darkGrey font-semibold" },
                            [_vm._v("Refund")]
                          ),
                          _vm.paymentDetails.refundDate
                            ? _c(
                                "p",
                                { staticClass: "text-lightGrey-2 text-xs" },
                                [_vm._v(_vm._s(_vm.paymentDetails.refundDate))]
                              )
                            : _c(
                                "p",
                                { staticClass: "text-lightGrey-2 text-xs" },
                                [_vm._v("Not available")]
                              ),
                        ]),
                      ]),
                      _c("div", { staticClass: "font-semibold" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatMoney")(
                              _vm.paymentDetails.refundedAmount
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("Hr", { staticClass: "mb-5" }),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.paymentRecords, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "flex justify-between items-center",
                class: { "mb-4": index !== _vm.paymentRecords.length - 1 },
              },
              [
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "svg",
                    {
                      staticClass:
                        "rounded-full mr-4 p-3 h-10 w-10 first-record",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "18",
                        height: "13",
                        viewBox: "0 0 18 13",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M5.50012 10.6163L2.00012 7.11633C1.61012 6.72633 0.990117 6.72633 0.600117 7.11633C0.210117 7.50633 0.210117 8.12633 0.600117 8.51633L4.79012 12.7063C5.18012 13.0963 5.81012 13.0963 6.20012 12.7063L16.8001 2.11633C17.1901 1.72633 17.1901 1.10633 16.8001 0.716328C16.4101 0.326328 15.7901 0.326328 15.4001 0.716328L5.50012 10.6163Z",
                          fill: "#10DF88",
                        },
                      }),
                    ]
                  ),
                  _c("div", [
                    _c("p", { staticClass: "text-darkGrey font-semibold" }, [
                      _vm._v("Paid"),
                    ]),
                    item.collectedAt
                      ? _c("p", { staticClass: "text-lightGrey-2 text-xs" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .dayjs(item.collectedAt)
                                  .format("MMMM DD YYYY")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatMoney")(item.amountCollected)) +
                      " "
                  ),
                ]),
              ]
            )
          }),
        ],
        2
      ),
      _vm.showReciept
        ? _c("Reciept", {
            attrs: {
              paymentDetails: _vm.paymentDetails,
              display: _vm.showReciept,
              recieptProp: _vm.recieptBlob,
            },
            on: {
              close: function ($event) {
                _vm.showReciept = false
              },
            },
          })
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.showPayNow,
            title: "How much do you want to pay",
          },
          on: {
            close: function ($event) {
              _vm.showPayNow = false
            },
          },
        },
        [
          _c("SinglePayment", {
            attrs: { type: "purchase", singleScheduleData: _vm.paymentDetails },
            on: { completed: _vm.handleCompleted },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showOverdueModal, title: "Interest breakdown" },
          on: {
            close: function ($event) {
              _vm.showOverdueModal = false
            },
          },
        },
        [
          _c("p", { staticClass: "text-sm text-lightGrey-2" }, [
            _vm._v("A 2% interest accumulates daily when you miss a payment."),
          ]),
          _c("div", { staticClass: "grid grid-cols-2 mt-3" }, [
            _c("p", { staticClass: "text-sm text-lightGrey-2" }, [
              _vm._v("Schedule:"),
            ]),
            _c("p", { staticClass: "text-dark" }, [
              _vm._v(_vm._s(_vm.singleOverduePayment.position)),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2 mt-3" }, [
            _c("p", { staticClass: "text-sm text-lightGrey-2" }, [
              _vm._v("Amount paid:"),
            ]),
            _c("p", { staticClass: "text-dark" }, [
              _vm._v(
                _vm._s(
                  _vm._f("formatMoney")(
                    _vm.singleOverduePayment.details.amountRecovered
                  )
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2 mt-3" }, [
            _c("p", { staticClass: "text-sm text-lightGrey-2" }, [
              _vm._v("Left to pay:"),
            ]),
            _c("p", { staticClass: "text-dark" }, [
              _vm._v(
                _vm._s(
                  _vm._f("formatMoney")(
                    _vm.singleOverduePayment.details.amountOwed
                  )
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2 mt-3" }, [
            _c("p", { staticClass: "text-sm text-lightGrey-2" }, [
              _vm._v("Late payment interest:"),
            ]),
            _c("p", { staticClass: "text-dark" }, [
              _vm._v(
                _vm._s(
                  _vm._f("formatMoney")(
                    _vm.singleOverduePayment.details.accruedOverdueInterest
                  )
                )
              ),
            ]),
          ]),
          _c("Hr", { staticClass: "my-4" }),
          _c(
            "div",
            { staticClass: "flex ites-center justify-between flex-wrap" },
            [
              _c(
                "p",
                { staticClass: "text-sm font-semibold text-lightGrey-2" },
                [_vm._v("Total amount owed")]
              ),
              _c("p", { staticClass: "text-sm font-semibold text-dark" }, [
                _vm._v(_vm._s(_vm._f("formatMoney")(3000))),
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-semibold text-brandPurple cursor-pointer",
                  on: {
                    click: function ($event) {
                      _vm.showBreakdown = true
                    },
                  },
                },
                [_vm._v("View details")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showBreakdown },
          on: {
            close: function ($event) {
              _vm.showBreakdown = false
            },
          },
        },
        [
          _c("OverduePaymentBreakdown", {
            attrs: {
              paymentBreakdown:
                _vm.singleOverduePayment.details.additionalInterests,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }