<template>
  <div class="gap-4 min-h-full">
    <!-- date filter -->
    <!-- <div class="width md:w-96 ml-auto grid grid-cols-2 gap-4 items-center mb-4">
      <Select
        type="filter"
        v-model="params.status"
        placeholder="Status"
        :reduce="(type) => type.value"
        :options="statusOptions"
      />
      <DatePicker
        v-model="range"
        is-range
        :model-config="modelConfig"
        :masks="masks"
        ref="calendar"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <Card class="flex items-center" p="px-2 py-3">
            <div
              @click="togglePopover()"
              class="flex w-full items-center relative justify-between cursor-pointer"
              v-if="inputValue.start === null"
            >
              <p class="text-grey text-sm">Select a date</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="16"
                fill="none"
              >
                <path
                  d="M12.3335 2.00033h-.6667v-.66667c0-.36667-.3-.66667-.6666-.66667-.3667 0-.6667.3-.6667.66667v.66667H3.66683v-.66667c0-.36667-.3-.66667-.66667-.66667-.36666 0-.66666.3-.66666.66667v.66667h-.66667c-.73333 0-1.33333.6-1.33333 1.33333V14.0003c0 .7334.6 1.3334 1.33333 1.3334H12.3335c.7333 0 1.3333-.6 1.3333-1.3334V3.33366c0-.73333-.6-1.33333-1.3333-1.33333Zm-.6667 11.99997H2.3335c-.36667 0-.66667-.3-.66667-.6666V5.33366H12.3335v8.00004c0 .3666-.3.6666-.6667.6666Z"
                  fill="#1E1452"
                />
              </svg>
            </div>

            <p v-else @click="togglePopover()" class="text-sm text-grey">
              {{ inputValue.start }} - {{ inputValue.end }}
            </p>
          </Card>
        </template>
      </DatePicker>
    </div> -->

    <Tabs :options="tabOptions" v-model="currentTab"/>
    <Pending v-if="currentTab === 'Pending Payment'"/>
    <Purchase
      v-if="currentTab === 'Purchase History'"
      @showPurchase="handleSinglePurchase"
    />
    <LargeModal
      background="#EBEAEE"
      :display="showPurchase"
      size="sm-large"
      @close="handleCloseSinglePurchase"
    >
      <SinglePurchase
        :singlePayment="singlePayment"
        :paymentDetails="paymentDetails"
        @completed="handleCompletedRepayment"
      />
    </LargeModal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Purchase from "./Purchase";
// import MobileView from "./MobileView"; //deprecated
import { DatePicker } from "v-calendar";
import { Button } from "@/UI/Button";
import { Select } from "@/UI/Input";
import { LargeModal } from "@/UI/Modals";
import Tabs from "@/UI/Tab/Tab";
import SinglePurchase from "./SinglePurchase";
import Pending from './Pending/Pending.vue'

export default {
  components: {
    // MobileFilter: () => import("./MobileFilter/MobileFilter.vue"),
    Purchase,
    // MobileView,
    LargeModal,
    Select,
    Button,
    SinglePurchase,
    DatePicker,
    Tabs,
    Pending
  },
  data: () => ({
    currentTab: "Pending Payment",
    tabOptions: ['Pending Payment', 'Purchase History'],
    showFilter: false,
    showPurchase: false,
    loading: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM",
    },
    // params: {
    //   orderId: "",
    //   page: 0,
    //   pageSize: 20,
    //   createdAtFrom: "",
    //   createdAtTo: "",
    //   paymentPlan: "",
    //   status: "SUCCESSFUL",
    //   sort: "DATE",
    // },
    statusOptions: [
      { label: "All", value: "" },
      { label: "Successful", value: "SUCCESSFUL" },
      { label: "Refunded", value: "REFUNDED" },
    ],
    paymentOptions: [
      {
        label: "All",
        value: "",
      },
      { label: "Instant", value: "INSTANT" },
      { label: "Split", value: "SPLIT" },
      {
        label: "Installment",
        value: "INSTALLMENT",
      },
      {
        label: "Defered",
        value: "DEFERRED",
      },
    ],
    paginateLoading: false,
    handleMore: false,
    paymentDetails: {},
    singlePayment: {},
  }),
  computed: {
    ...mapState({
      // getPurchases: (state) => state.purchases.getPurchases,
      purchaseHistory: (state) => state.purchases.purchaseHistory,
      merchantPurchasesHistory: (state) => state.purchases.productPurchasesHistory,
      categoryPurchaseHistory: (state) => state.purchases.categoryPurchaseHistory,
    }),
    getPurchaseHistoryToView(){
      let view
      switch(this.params?.sort){
        case 'DATE':
          view = this.purchaseHistory;
        break;
        case 'MERCHANT_CATEGORY':
          view = this.merchantPurchasesHistory;
          break;
        case 'MERCHANT':
          view = this.categoryPurchaseHistory;
          break;
      }
      return view
    }
  },
  // watch: {
  //   "range.end"() {
  //     this.params.createdAtFrom = this.range.start;
  //     this.params.createdAtTo = this["range.end"];
  //     this.callPurchases();
  //   },

  //   "params.status"() {
  //     this.callPurchases();
  //   }
  // },
  mounted(){
    if(this.$route.query.id){
      this.handleSinglePurchase({id:this.$route.query.id, doNotRoute: true })
    }
  },
  // created() {
  //   if(this.purchaseHistory.length === 0){
  //     this.callPurchases();
  //   }
  // },
  methods: {
    ...mapActions("purchases", [
      "getUserPurchases",
      "getPurchaseHistory",
      "getSinglePurchase",
    ]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("repayments", [ "getUpcomingPayments"]),


    // getRecentDetails() {
    //   this.showPurchase = false;
    //   this.callPurchases();
    // },

    handleSinglePurchase(item) {
      this.setLoading(true);
      if(!item.doNotRoute){
        this.$router.replace({
        path:'/purchases',
        query: {id: item.id}
      })
      }
      this.getSinglePurchase(item.id)
        .then((res) => {
          this.paymentDetails = res.data;
          this.setLoading(false);
          this.showPurchase = true;
        })
        .catch(() => {
          this.setLoading(false);
          this.showPayment = false;
        });
    },
    handleCloseSinglePurchase(){
      this.showPurchase = false;
      this.$router.replace({
        path:'purchases',
        query:{}
      })
    },
    // callPurchases(type) {
    //   // if it is pagination type show loading for pagination else general loading
    //   type === "paginate"
    //     ? (this.paginateLoading = true)
    //     : (this.loading = true);
    //   this.getPurchaseHistory({
    //     params: this.params,
    //     type: type,
    //   })
    //     .then((res) => {
    //       if (res.data.length === this.params.pageSize) {
    //         this.handleMore = true;
    //       } else {
    //         this.handleMore = false;
    //       }
    //       type === "paginate"
    //         ? (this.paginateLoading = false)
    //         : (this.loading = false);
    //     })
    //     .catch(() => {
    //       type === "paginate"
    //         ? (this.paginateLoading = false)
    //         : (this.loading = false);
    //     });
    // },
    // handleLoading(type) {
    //   type === "paginate"
    //     ? (this.paginateLoading = false)
    //     : (this.loading = false);
    // },
    // handlePagination() {
    //   this.paginateLoading = true;
    //   this.params.page++;
    //   this.callPurchases("paginate");
    // },
    handleSearch() {
      this.params.page = 0;
      this.params.createdAtFrom = this.range.start;
      this.params.createdAtTo = this.range.end;
      this.callPurchases();
    },
  //   handleInput(e) {
  // console.log(e, 'e')
  //     if(e === 'Purchase History'){
  //       //  if(!this.purchaseHistory.length){
  //           // this.params.sort = option;
  //           this.callPurchases({ params: this.params });
  //         // }
  //     }
  //     // switch (e) {
  //     //   case "Product":
  //     //     option = 'DATE'
  //     //     if(!this.purchaseHistory.length){
  //     //       this.params.sort = option;
  //     //       this.callPurchases({ params: this.params });
  //     //     }
  //     //     break;
  //     //   case "Category":
  //     //     option = 'MERCHANT_CATEGORY'
  //     //     if(!this.merchantPurchasesHistory.length){
  //     //       this.params.sort = option;
  //     //       this.callPurchases({ params: this.params });
  //     //     }
  //     //     break;
  //     //   case "Merchant":
  //     //     option = 'MERCHANT'
  //     //     if(!this.categoryPurchaseHistory.length){
  //     //       this.params.sort = option;
  //     //       this.callPurchases({ params: this.params });
  //     //     }
  //     //     break;
  //     //   default:
  //     //     option = "";
  //     // }
  //     this.params.sort = option;
  //     // this.callPurchases({ params: this.params });
  //   },
    handleCompletedRepayment(){
      this.showPurchase  = false;
      this.$store.commit("repayments/RESET_REPAYMENT_STATE");
      this.getPurchaseHistory()
      // this.callPurchases()
    }
  },
};
</script>
<style scoped>
@import "~@/assets/styles/table.css";
</style>
